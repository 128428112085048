.navbar-main {
    background-color: #b2a9e8;
}

.btn-sign {
    padding: 4px 16px;
    font-size: 15px;
    border-radius: 14.5px;
    color: #ffffff;
    background-color: #e8b72d;
}

.logo-icon {
    width: 24px;
    height: 24px;
}
