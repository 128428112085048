.example-image {
    width: 433px;
    border-radius: 25px;
}

.section-row {
    padding: 30px 0;
}

.has-border {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.without-bullets {
    list-style-type: none;
    margin-left: -30px;
}

@media only screen and (max-width: 600px) {
    .example-image {
        width: 100% !important;
        height: 50% !important;
    }
}
