/* Hero Section for main product tagline */

.hero-section {
    background-color: #b2a9e8;
}

.hero-section-title {
    font-weight: 700;
    color: #eac613;
    text-align: left;
}

.hero-section-text {
    color: #ffffff;
    font-weight: 400;
    max-width: 650px;
    text-align: left;
}

.hero-section-image {
    max-height: 859px;
}

/* Product section */

.product-section {
    background-color: #8e81dd /* #10152B */;
    padding: calc(2rem + 2vw);
}

.product-section-title {
    font-weight: 600;
    text-align: left;
}

.product-section-subtitle {
    font-weight: 400;
    color: #eac613;
}

.product-section-icons {
    max-width: 100px;
    max-height: 95px;
}

/* Company section */

.company-section {
    background-color: #b2a9e8 /* #10152B */;
    padding: calc(2rem + 2vw);
}

.company-section-title {
    font-weight: 600;
    text-align: left;
}

.company-section-subtitle {
    font-weight: 400;
    color: #eac613;
}

.company-section-icons {
    max-width: 320px;
    max-height: 310px;
}

/* FAQ Section */

.faq-section {
    background-color: #8e81dd;
}

.accordion-body {
    background-color: #8e81dd;
    color: #ffffff;
    text-align: left;
}

.accordion-button {
    background-color: #8e81dd;
    color: #ffffff;
}

.accordion-button:not(.collapsed) {
    background-color: #b2a9e8;
    color: #ffffff;
}

.accordion-header {
    background-color: #10152b;
    color: #ffffff;
}

.accordion-item {
    background-color: #10152b;
    color: #ffffff;
    border-bottom: 1px solid #eac613;
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23EAC613'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* helper formats */
.section-left {
    display: flex;
    justify-content: left;
}

.bullet-list-header {
    margin-left: 12px;
}

@media only screen and (max-width: 600px) {
    .display-4 {
        font-size: 24px;
    }
    .fs-4 {
        font-size: 16px !important;
    }

    .row-card-image {
        width: 100px;
        height: 100px;
    }

    .product-section-subtitle {
        margin-top: 50px;
    }

    .section-left {
        padding-left: 40px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
    .hero-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .product-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .company-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .display-1 {
        font-size: 48px;
    }
    .display-5 {
        font-size: 28px;
    }

    .section-left {
        padding-left: calc(1rem + 1vh) calc(4rem + 4vw);
    }
}

@media only screen and (min-width: 1100px) {
    .hero-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .product-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .company-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .display-5 {
        font-size: 40px;
    }

    .third-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }

    .faq-section {
        padding: calc(1rem + 1vh) calc(4rem + 4vw);
    }
}
