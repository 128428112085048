.footer-container {
    border: 1px solid #10152b;
    padding: 50px;
}

.social-icons {
    height: 15px;
    width: 15px;
}

.social-icons-link {
    padding: 6px;
}

.nav-link-footer {
    font-size: 12px;
    white-space: nowrap;
}

.footer-header {
    font-size: 14px;
}

.kontakt-images {
    width: 12px;
    height: 12px;
}

.footer-brand {
    margin-left: 15px;
}

.p6 {
    font-family: Tahoma, sans-serif;
}

@media only screen and (max-width: 750px) {
    .footer-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-container {
        padding: 40px 20px;
    }
}
